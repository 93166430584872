import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

export const passwordMatchValidation = {
  name: 'passwordMatch',
  regExp: /g/,
  message: '@@PASSWORD_NOT_MATCH',
  error: `Passwords must match`,
};

export const passwordStrength = {
  name: 'passwordStrength',
  regExp: /g/,
  message: '@@PASSWORD_NOT_STRENGTH',
  error: `Password must contain numbers, lowercase letters, uppercase letters, or special characters.`,
};

@Injectable()
export class CustomValidators {
  constructor() {
  }

  passwordMatch(controlName: string, matchingControlName: string): (formGroup: FormGroup) => void {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      const errors = control.errors || {};
      if (control.value !== matchingControl.value) {
        errors[passwordMatchValidation.name] = passwordMatchValidation;
        control.setErrors(errors);
      } else {
        delete errors[passwordMatchValidation.name];
        if (Object.keys(errors).length) {
          control.setErrors(errors);
        } else {
          control.setErrors(null);
        }
      }
    };
  }

  passwordStrengthValidator(controlName: string): (formGroup: FormGroup) => void {
    const upperCaseCharacters = new RegExp(/[A-Z]+/);
    const lowerCaseCharacters = new RegExp(/[a-z]+/);
    const numberCharacters = new RegExp(/[0-9]+/);
    const specialCharacters = new RegExp(/[!"#$%&'\\()*§+±,-./:;<=>?@[\]^_`{|}~]/);

    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const errors = control.errors || {};
      if (!upperCaseCharacters.test(control.value)) {
        errors[passwordStrength.name] = 'Password must contain numbers, lowercase letters, uppercase letters, or special characters.';
        control.setErrors(errors);
        return { passwordStrength: `Upper case required` };
      }
      if (!lowerCaseCharacters.test(control.value)) {
        errors[passwordStrength.name] = 'Password must contain numbers, lowercase letters, uppercase letters, or special characters.';
        control.setErrors(errors);
        return { passwordStrength: `lower case required` };
      }
      if (!numberCharacters.test(control.value)) {
        errors[passwordStrength.name] = 'Password must contain numbers, lowercase letters, uppercase letters, or special characters.';
        control.setErrors(errors);
        return { passwordStrength: `number required` };
      }
      if (!specialCharacters.test(control.value)) {
        errors[passwordStrength.name] = 'Password must contain numbers, lowercase letters, uppercase letters, or special characters.';
        control.setErrors(errors);
        return { passwordStrength: `Special char required` };
      }
      return null;
    };
  }
}
